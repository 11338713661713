import { baseUrl } from "./config";

const awsconfig = {
	aws_project_region: "ap-southeast-1",
	aws_cognito_identity_pool_id: "ap-southeast-1:9a5331da-329a-497d-be61-15668b42bf8f",
	aws_cognito_region: "ap-southeast-1",
	aws_user_pools_id: "ap-southeast-1_f6tfrmIsS",
	aws_user_pools_web_client_id: "1cum79568pe0u434ek1d5kin9a",
	"authenticationFlowType": 'USER_SRP_AUTH',
	oauth: {
		domain: "dev-auth.konfhub.com",
		redirectSignIn: baseUrl,
		scope: ["email", "openid", "aws.cognito.signin.user.admin"],
		redirectSignOut: baseUrl,
		responseType: "code"
	},
	cookieStorage: {
		domain: ".konfhub.com",
		secure: false,
		path: "/",
		expires: 365
	}
};

export default awsconfig;
