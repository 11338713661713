const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
const compareDate = new Date(process?.env?.COMPARE_DATE ?? '2024-07-11T00:00:00.000+05:30');

export const hostEventUrl = "https://dev-events.konfhub.com";
export const certhubUrl = "https://dev-cert.konfhub.com";
export const quizhubUrl = "https://dev-quiz.konfhub.com";
export const baseUrl = "https://dev.konfhub.com";
export const loginUrl = "https://dev-accounts.konfhub.com";
export const gMapApiKey = "AIzaSyBkCKa3Rg5BeGbk2uxvOVFEbLcxNulxLOc";
export const GoogleAnalyticsId = "G-02TDXKQYLG";
export const ClarityId = "hez36l9h0e";
export const s3Url = "https://konfhub-image-files-dev.s3.ap-southeast-1.amazonaws.com";
export const mediaUrl = "https://dev-media.konfhub.com";
export const xApiKey = "d2252ec3-bf55-4d62-bf6e-d9dff52b6172";
export const publicStripePublishableKey = "pk_test_51Nx4nbSBwIcmEW9pktp33m6yaXqdSgxS8H4XBAG2CKxWE3FteP6oaLWyKDKCAxE6JgYYiE4TzW7b2Hdr5MC81mUb00W21j4XfU";
export const facebook_id = "429779859341568";
export const newRazorPayEventIds = []
export const pgBasic = 74957;
export const pgPlus = 74958;
export const pgUltimate = 74959;

export const razorpayKey =
	currentDate - compareDate < 0
		? "rzp_test_ZMcokv2rt3vDAB"
		: "rzp_test_uv3UwYoeD6zPql";
export const newRazorPayKey = "rzp_test_uv3UwYoeD6zPql";

const maintenanceStartDate = new Date(
	"2024-10-31T03:20:00.000+05:30"
);
const maintenanceEndDate = new Date(
	"2024-10-31T03:30:00.000+05:30"
);

export const isMaintenanceTime =
	currentDate > maintenanceStartDate && currentDate < maintenanceEndDate;
